<template>
  <div>
    <b-card title="Güncelle">
      <b-form-group label="Başlık">
        <b-form-input
          v-model="gallery.title"
          placeholder="Başlık"
        />
      </b-form-group>
      <b-form-group label="Metin">
        <b-form-textarea
          v-model="gallery.content"
          placeholder="Metin"
        />
      </b-form-group>
    </b-card>
    <b-card title="Kapak Fotoğrafı">
      <b-row>
        <b-col
          v-if="imageUrl"
          xs="12"
          md="3"
        >
          <b-img
            :src="imageUrl"
            fluid
          />
        </b-col>
        <b-col>
          <b-form-group label="Kapak Fotoğrafı">
            <b-form-file
              v-model="gallery.upload_image"
              placeholder="Bir görsel seçin veya buraya bırakın..."
              drop-placeholder="Görseli buraya bırakın..."
              browse-text="Kapak Fotoğrafı Seçin"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <b-card title="Galeri Fotoğrafları">
      <b-form-group label="Fotoğraf Yükle">
        <b-form-file
          v-model="gallery.upload_images"
          placeholder="Bir görsel seçin veya buraya bırakın..."
          drop-placeholder="Görseli buraya bırakın..."
          browse-text="Fotoğrafları Seçin"
          multiple
        />
      </b-form-group>
      <hr>
      <b-row class="match-height">
        <b-col
          v-for="image in gallery.images"
          :key="image.id"
          xs="12"
          sm="6"
          md="3"
          class="text-center mb-2"
        >
          <b-img
            rounded
            :src="folderUrl+image.name"
            fluid
          />
          <b-button
            variant="outline-danger"
            block
            class="mt-1"
            size="sm"
            @click="removeImage(image.id)"
          >
            <FeatherIcon icon="XIcon" /> Fotoğrafı Sil
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <b-button
      variant="danger"
      :disabled="gallery.submitStatus"
      @click="submitForm"
    >
      Kaydet
    </b-button>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BImg,
  BCard,
  BFormInput,
  BFormTextarea,
  BFormFile,
  BFormGroup,
  BButton,
} from 'bootstrap-vue'

export default {
  name: 'Add',
  components: {
    BRow,
    BCol,
    BImg,
    BCard,
    BFormInput,
    BFormTextarea,
    BFormFile,
    BFormGroup,
    BButton,
  },
  data() {
    return {
      submitStatus: false,
      imageUrl: null,
      folderUrl: null,
    }
  },
  computed: {
    gallery() {
      const data = this.$store.getters['gallery/getGalleryPhoto']
      if (data.cover) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.imageUrl = `${this.$store.state.app.baseURL}/media/portal/gallery/${data.cover}`
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.folderUrl = `${this.$store.state.app.baseURL}/media/portal/gallery/`
      }
      return data
    },
    saveStatus() {
      return this.$store.getters['gallery/getGalleryPhotoSaveStatus']
    },
  },
  watch: {
    saveStatus(val) {
      if (val.status === true) {
        this.getGallery()
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.gallery.submitStatus = false
    },
  },
  beforeCreate() {
    this.$store.dispatch('gallery/galleryPhotoReset')
  },
  created() {
    this.getGallery()
  },
  methods: {
    getGallery() {
      this.$store.dispatch('gallery/galleryPhotoView', this.$route.params.id)
    },
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
    submitForm() {
      this.submitStatus = true
      this.$store.dispatch('gallery/galleryPhotoSave', this.gallery)
    },
    removeImage(id) {
      this.$store.dispatch('gallery/galleryDeleteImage', id)
    },
  },
}
</script>
